import { createApp } from "vue"
import app from "../../../app.vue"
import store from "../../../store"
import router from "./router"
import { createVfm } from "vue-final-modal"
import { Vue3ProgressPlugin } from "@marcoschulte/vue3-progress"
import * as uiv from "uiv"
// import VueMaterial from "vue-material"
// import { MdProgress, MdField } from "vue-material/dist/components"

console.log("%cwaaq link", "color: #2c96ff; font-weight: bold; font-size: 32px; font-family: syncopate")
const App = createApp(app)
App.use(store)
App.use(router)
// App.config.productionTip = false
App.use(Vue3ProgressPlugin, {
    color: "#ff8c72",
    failedColor: "red",
    thickness: "4px",
})

// App.config.productionTip = false
App.use(uiv)
const vfm = createVfm()
App.use(vfm)
// Vue.use(VueMaterial)
// App.use(MdProgress)
// App.use(MdField)

App.mount("#app")
